import { template as template_ba39c548d38b4e3db33af1efd5cf2b9c } from "@ember/template-compiler";
import bodyClass from "discourse/helpers/body-class";
const AddTopicStatusClasses = template_ba39c548d38b4e3db33af1efd5cf2b9c(`
  {{#if @topic.invisible}}
    {{bodyClass "topic-status-unlisted"}}
  {{/if}}
  {{#if @topic.pinned}}
    {{bodyClass "topic-status-pinned"}}
  {{/if}}
  {{#if @topic.unpinned}}
    {{bodyClass "topic-status-unpinned"}}
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default AddTopicStatusClasses;
