import { template as template_9aeeb357dfeb4c58b9c69fa9cefd50f3 } from "@ember/template-compiler";
const SidebarSectionMessage = template_9aeeb357dfeb4c58b9c69fa9cefd50f3(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
