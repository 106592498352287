import { template as template_3a743956dbf941ea8d0a1a62bbf0f7f0 } from "@ember/template-compiler";
const FKControlMenuContainer = template_3a743956dbf941ea8d0a1a62bbf0f7f0(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
